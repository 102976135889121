/*  /cover = fit image in the div*/
.share__container {
  height: 100vh;
  padding: 5rem 0;
}
.share-img {
  padding: 0 3rem;
}

.share-btn {
  margin: 0 auto;
  width: 100%;
}

.fb-share {
  margin: 0 auto;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 0 2rem 2rem 0;
  /* background-color: brown; */
}

/* .fb-icon { */
/* display: flex;
  align-items: center;
  justify-content: center; */
/* width: 2.5rem; */
/* height: 2rem; */
/* background-color: var(--color-white); */
/* color: var(--color-primary); */
/* border-radius: 2rem 0 0 2rem; */
/* } */

.fb-icon {
  width: 2.5rem;
  height: 2rem;
  background-color: var(--color-white);
  color: var(--color-primary);
  border-radius: 2rem 0 0 2rem;
  padding: 6px 0;
}

.btn {
  height: 2rem;
  border-radius: 0 2rem 2rem 0;
  padding: 0 9rem 0 6rem;
}

@media screen and (max-width: 1200px) {
  .container {
    width: var(--container-width-lg);
  }

  .share__container {
    padding: 14rem 0;
  }
  .share-img {
    padding: 0 1rem;
  }
}

@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-lg);
  }

  .share__container {
    padding: 10rem 0;
  }

  .share-img {
    padding: 0 1rem;
  }
}

@media screen and (max-width: 820px) {
  .container {
    width: var(--container-width-md);
  }

  .share__container {
    padding: 15rem 0;
  }
  .share-img {
    padding: 0 1rem;
  }

  .fb-share,
  .btn,
  .fb-icon {
    height: 3.3rem;
  }

  .fb-icon {
    width: 3.2rem;
    padding: 9px 0;
  }

  .btn {
    /* padding: 0.2rem 7rem; */
    padding: 0 9rem 0 6rem;
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .container {
    width: var(--container-width-md);
  }
  .share__container {
    padding: 15rem 0;
  }
  .share-img {
    padding: 0 1rem;
  }

  .fb-share,
  .btn,
  .fb-icon {
    height: 3.3rem;
  }

  .fb-icon {
    width: 3.2rem;
    padding: 9px 0;
  }

  .btn {
    padding: 0 9rem 0 6rem;
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 480px) {
  .container {
    width: var(--container-width-sm);
  }

  .share__container {
    padding: 9rem 0;
  }

  .share-img {
    padding: 0 0.5rem;
  }

  .fb-share,
  .btn,
  .fb-icon {
    height: 1.8rem;
  }

  .fb-icon {
    width: 2rem;
    padding: 6px 0;
  }

  .btn {
    padding: 0 5rem 0 3rem;
    font-size: 1rem;
  }
}

/* LANDSCAPE HEIGHT */
@media screen and (max-height: 768px) {
  .share__container {
    padding: 3rem 0;
  }

  .share-img {
    padding: 0 1rem;
  }
}

@media screen and (max-height: 320px) {
  .share__container {
    padding: 0.4rem 0;
  }

  .share-img {
    padding: 0 3rem;
  }
}
