* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
}
:root {
  --color-primary: hsl(220, 84%, 48%);
  --color-primary-bg: hsl(220, 84%, 40%);
  --color-white: white;
  --transition: all 400ms easy;
  --container-width-lg: 50%;
  --container-width-md: 85%;
  --container-width-sm: 95%;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

body {
  color: white;
  background: url("https://demobot.inq.news/assets/HOTD_bg2.jpg") no-repeat
    center / cover;
}

.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

img {
  width: 100%;
  display: block;
  object-fit: cover;
}

.btn {
  width: max-content;
  display: inline-block;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  border-radius: 2rem;
  padding: 0 6rem;
  font-size: 15px;
  color: var(--color-primary);
  transition: var(--transition);
}

.btn-primary {
  background: var(--color-primary);
  color: var(--color-white);
}

.btn:hover {
  border-color: transparent;
  background: var(--color-primary-bg);
  color: var(--color-white);
}

@media screen and (max-width: 768px) {
  .container {
    width: var(--container-width-md);
  }

  .btn {
    padding: 1rem 10rem;
  }
}

@media screen and (max-width: 480px) {
  .container {
    width: var(--container-width-sm);
  }
  .btn {
    padding: 0.5rem 6rem;
  }
}
